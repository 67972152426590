<input
  class="par-control hrp-multiselect-searchbar__input !w-full !border-none"
  type="text"
  [disabled]="$disabled()"
  [placeholder]="'listbox.search-placeholder' | transloco"
  [(ngModel)]="$query"
  (keydown)="$keyDown.set($event)"
/>
@if ($query().length > 0) {
<button
  class="hrp-multiselect-searchbar__close_button"
  type="button"
  [disabled]="$disabled()"
  (click)="$query.set('')"
>
  <ng-icon
    name="matCloseOutline"
    class="hrp-multiselect-searchbar__close_icon"
  ></ng-icon>
</button>
}